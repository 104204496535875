<template>
  <Bandbwash />
</template>

<script>
import Bandbwash from "../../../components/bandb_wash";

export default {
  name: "bandb_wash",
  components: {
    Bandbwash,
  },
};
</script>