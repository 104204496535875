<template>
  <dv-full-screen-container class="bj" style="width: 100%; height: 100%">
    <dv-border-box-8 style="background: rgba(22, 40, 80, 0.8)">
      <!-- 订单 -->
      <div v-if="show" class="boxdiv">
        <dv-border-box-7 :color="['#3D5AA1']" class="popup">
          <div>
            <div class="onheard">
              <img src="../../public/singran.png" alt="" class="heard" />
              <div style="color: #40c5ff">芯任收污送洗</div>
            </div>
            <div style="padding-top: 20px; font-size: 16px; color: #40c5ff">
              下单时间：{{ xin.date }}
            </div>
            <van-cell-group v-for="e in assetsdetailList" :key="e">
              <van-cell>
                <template #title>
                  <div
                    style="text-align: left; padding-left: 20px; color: #40c5ff"
                  >
                    {{ e.name }}
                  </div>
                </template>
                <template #default>
                  <div style="padding-right: 20px; color: #40c5ff">
                    {{ e.count }}
                  </div>
                </template>
              </van-cell>
            </van-cell-group>
            <van-cell>
              <template #title>
                <div
                  style="text-align: left; padding-left: 20px; color: #40c5ff"
                >
                  总数
                </div>
              </template>
              <template #default>
                <div style="padding-right: 20px; color: #40c5ff">
                  {{ xin.count }}
                </div>
              </template>
            </van-cell>
            <div>
              <!-- <div class="dialog">
                <div class="dialog_right">工厂:</div>
                <div class="dialog_left">{{ factory.name }}</div>
              </div>
              <div class="dialog">
                <div class="dialog_right">收件人:</div>
                <div class="dialog_left">{{ factory.contact }}</div>
              </div>
              <div class="dialog">
                <div class="dialog_right">联系电话:</div>
                <div class="dialog_left">{{ factory.mobile }}</div>
              </div> -->
              <div class="dialog">
                <div class="dialog_right">收污地址:</div>
                <div class="dialog_left">{{ device?.hotel.address }}</div>
              </div>
              <div class="dialog">
                <div class="dialog_right">送洗地址:</div>
                <div class="dialog_left">{{ factory.address }}</div>
              </div>
            </div>
            <div class="dialog_button">
              <dv-border-box-12
                class="dialog_button_but"
                @click="
                  () => {
                    show = !show;
                    click = false;
                  }
                "
                >取消</dv-border-box-12
              >
              <dv-border-box-12 class="dialog_button_but" @click="submit"
                >确定</dv-border-box-12
              >
            </div>
          </div>
        </dv-border-box-7>
      </div>
      <div v-if="showorder" class="boxdiv">
        <dv-border-box-7 :color="['#3D5AA1']" class="popup">
          <div>
            <div class="onheard">
              <img src="../../public/singran.png" alt="" class="heard" />
              <div style="color: #40c5ff">芯任卫生联防联控云平台</div>
            </div>
            <div style="padding-top: 20px; font-size: 16px; color: #40c5ff">
              请选择配送员：
            </div>
            <div class="height">
              <van-cell-group v-for="(e, i) in recvstaffinfo" :key="e">
                <van-cell @click="senses(e, i)">
                  <template #title>
                    <div>
                      <div
                        style="
                          text-align: left;
                          padding-left: 20px;
                          color: #40c5ff;
                        "
                      >
                        {{ e.name }}
                      </div>
                      <div
                        style="
                          text-align: left;
                          padding-left: 20px;
                          color: #1293f6;
                        "
                      >
                        {{ e.phone }}
                      </div>
                      <div></div>
                    </div>
                  </template>
                  <template #default>
                    <div
                      style="
                        padding-right: 20px;
                        display: flex;
                        justify-content: flex-end;
                      "
                    >
                      <van-radio
                        :name="i"
                        v-model="recvstaff_count"
                        shape="square"
                      ></van-radio>
                    </div>
                  </template>
                </van-cell>
              </van-cell-group>
            </div>

            <div class="dialog_button">
              <dv-border-box-12 class="dialog_button_but" @click="call"
                >取消</dv-border-box-12
              >
              <dv-border-box-12 class="dialog_button_but" @click="jump"
                >跳过</dv-border-box-12
              >
              <dv-border-box-12 class="dialog_button_but" @click="submits"
                >确定</dv-border-box-12
              >
            </div>
          </div>
        </dv-border-box-7>
      </div>
      <!-- 预览 -->
      <van-row
        type="flex"
        style="height: 100%; padding: 10px"
        v-if="!status_percentage"
      >
        <van-col span="24" style="height: 5%; display: flex; margin: 5px">
          <div style="width: 25%">
            <div class="info">
              <img
                :src="device.bandb?.avatar"
                class="logo"
                v-if="device.bandb?.avatar"
              />
              <img
                src="../assets/slogan.png"
                class="logos"
                v-if="!device.bandb?.avatar"
              />
              <div>{{ device?.bandb?.name }} {{ device?.hotel?.name }}</div>
            </div>
            <div
              style="display: flex; margin-top: 20px; align-items: flex-end"
              v-if="staff_info"
            >
              <img :src="staff_info.avatar" alt="" class="logo" />
              <div style="font-size: 20px; color: #fff; line-height: 35px">
                {{ staff_info?.name }}
              </div>
              <div style="font-size: 20px; color: #fff; line-height: 35px">
                【{{ staff_info?.role }}】
              </div>
            </div>
          </div>
          <div style="width: 50%">
            <div class="onheard">
              <img src="../../public/singran.png" alt="" class="heard" />
              <div class="heardtitle">芯任收污送洗</div>
            </div>
          </div>
          <div class="device">
            <div>版本号：{{ num }}</div>
            <div v-if="device.device?.model">
              设备型号：{{ device.device?.model }}
            </div>
            <div v-if="device.device?.id">
              设备编号：{{ device.device?.id }}
            </div>
            <div class="duka">
              读卡设备：<span :style="duka.color">{{ duka.text }}</span>
            </div>
          </div>
        </van-col>
        <van-col span="24" style="height: 70%; display: flex; margin: 5px">
          <div class="preview">
            <dv-decoration-9
              style="width: 350px; height: 350px; margin: 10px auto"
              >{{ status_msg }}</dv-decoration-9
            >
            <!-- <div
              style="
                width: 140px;
                height: 70px;
                line-height: 70px;
                margin: 0 auto;
              "
              @click="refresh"
            >
              <dv-border-box-12>刷新</dv-border-box-12>
            </div> -->
            <div style="font-size: 24px; margin-bottom: 20px">
              <!-- <van-icon name="success" color="#1ED76D" v-if="status" /><van-icon
                name="cross"
                color="red"
                v-if="!status"
              /> -->
            </div>
            <div class="status">
              <div
                class="status_msg"
                v-for="(item, index) in status_msgs"
                :key="index"
              >
                <van-icon
                  name="success"
                  color="#1ED76D"
                  v-if="item.status"
                /><van-icon
                  name="cross"
                  color="red"
                  v-if="!item.status && !item.wran"
                />
                <van-icon
                  name="warn-o"
                  color="yellow"
                  v-if="item.wran && !item.status"
                />{{ item.msg }}{{ item.status ? "成功" : "失败" }}
              </div>
            </div>
          </div>
          <van-button
            class="buttonS"
            type="success"
            :loading="loading"
            loading-type="spinner"
            @click="resetdevice"
            >重置读卡
          </van-button>
          <van-button
            class="button"
            type="success"
            @click="restarts"
            :disabled="status"
            >重启</van-button
          >
        </van-col>
      </van-row>
      <!-- 进入页面 -->
      <van-row
        type="flex"
        style="height: 100%; padding: 10px"
        v-if="status_percentage"
      >
        <van-col span="24" style="height: 5%; display: flex; margin: 5px">
          <div style="width: 25%">
            <div class="info">
              <img
                :src="device.bandb?.avatar"
                class="logo"
                v-if="device.bandb?.avatar"
              />
              <img
                src="../assets/slogan.png"
                class="logos"
                v-if="!device.bandb?.avatar"
              />
              <div>{{ device?.bandb?.name }} {{ device?.hotel?.name }}</div>
            </div>
            <div class="infotime">{{ xin.date || time }}</div>
            <div class="infotime" v-if="xin.code">批次号：{{ xin.code }}</div>
          </div>
          <div style="width: 50%">
            <div class="onheard">
              <img src="../../public/singran.png" alt="" class="heard" />
              <div class="heardtitle">芯任卫生联防联控云平台</div>
            </div>
          </div>
          <div class="device">
            <div>版本号：{{ num }}</div>
            <div v-if="device.device?.model">
              设备型号：{{ device.device?.model }}
            </div>
            <div v-if="device.device?.id">
              设备编号：{{ device.device?.id }}
            </div>
            <div class="duka">
              读卡设备：<span :style="duka.color">{{ duka.text }}</span>
            </div>
          </div>
        </van-col>
        <van-col span="14" style="height: 85%; padding: 0 10px 0px 10px">
          <dv-border-box-6
            :color="['#3E6BAF', '#30EAF9']"
            style="padding-top: 10px"
          >
            <van-tabs>
              <van-tab title="房间" name="1">
                <van-pull-refresh
                  v-model="state.refreshing"
                  @refresh="onRefresh"
                  class="overflow"
                  style="height: 4.2rem"
                >
                  <van-list :finished="state.finished" @load="onLoad">
                    <van-collapse v-model="activeNames" accordion>
                      <van-collapse-item
                        :name="index"
                        v-for="(item, index) in state.list"
                        :key="item"
                      >
                        <template #title>
                          <van-cell
                            ><template #icon>
                              <img
                                :src="item.picture"
                                alt=""
                                style="
                                  width: 160px;
                                  height: 100px;
                                  margin-left: 25px;
                                "
                              />
                            </template>
                            <template #title>
                              <div
                                style="
                                  color: #0499d1;
                                  text-align: left;
                                  padding-left: 10px;
                                "
                              >
                                {{ item.code }} {{ item.hotel_name }} 房号：{{
                                  item.number
                                }}
                              </div>
                            </template>
                            <template #label>
                              <div
                                style="
                                  font-size: 20px;
                                  text-align: left;
                                  padding-left: 10px;
                                "
                              >
                                {{ item.room_type_name }}
                              </div>
                            </template>
                          </van-cell>
                        </template>
                        <template #value>
                          <div
                            style="
                              color: #fff;
                              line-height: 100px;
                              font-size: 30px;
                            "
                          >
                            {{ item.count }}/{{ item.bucao_count }}
                          </div>
                        </template>
                        <div
                          v-for="data in item.bucaos"
                          :key="data"
                          class="bucaos"
                        >
                          <div class="bucaos">
                            <div>
                              {{ data.name }}:{{ data.read_count || 0 }}
                            </div>
                          </div>
                        </div>
                      </van-collapse-item>
                    </van-collapse>
                  </van-list>
                </van-pull-refresh>
              </van-tab>
              <van-tab title="房东" name="2">
                <van-pull-refresh
                  v-model="state.refreshing"
                  @refresh="onRefresh"
                  class="overflow"
                  style="height: 4.2rem"
                >
                  <van-list
                    :finished="state.finished"
                    @load="onLoad"
                    v-if="state.bucao_summary.length > 0"
                  >
                    <van-collapse v-model="activeNames" accordion>
                      <van-collapse-item
                        :name="index"
                        v-for="(item, index) in state.bucao_summary"
                        :key="item"
                      >
                        <template #title>
                          <van-cell
                            ><template #icon>
                              <!-- <img
                                :src="item.picture"
                                alt=""
                                
                              /> -->
                              <div class="avatar">{{ item.avatar }}</div>
                            </template>
                            <template #title>
                              <div
                                style="
                                  color: #0499d1;
                                  text-align: left;
                                  padding-left: 10px;
                                "
                              >
                                {{ item.name }}
                              </div>
                            </template>
                            <template #label>
                              <div
                                style="
                                  font-size: 20px;
                                  text-align: left;
                                  padding-left: 10px;
                                "
                              >
                                {{ item.bandb_owner_code }}
                              </div>
                            </template>
                          </van-cell>
                        </template>
                        <template #value>
                          <div
                            style="
                              color: #fff;
                              line-height: 100px;
                              font-size: 30px;
                            "
                          >
                            {{ item.count }}
                          </div>
                        </template>
                        <div
                          v-for="data in item.bucaos"
                          :key="data"
                          class="bucaos"
                        >
                          <div class="bucaos">
                            <div>{{ data.name }}:{{ data.count || 0 }}</div>
                          </div>
                        </div>
                      </van-collapse-item>
                    </van-collapse>
                  </van-list>
                </van-pull-refresh>
              </van-tab>
            </van-tabs>
          </dv-border-box-6>
        </van-col>
        <van-col span="10" style="height: 85%">
          <dv-border-box-13 class="btn">
            <div class="border" :style="{ height: listheight }">
              <div class="app-card-content" v-if="assetsdetailList.length < 1">
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">床单</div>
                    </div>
                  </dv-border-box-7>
                </div>
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">被套</div>
                    </div>
                  </dv-border-box-7>
                </div>
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">枕套</div>
                    </div>
                  </dv-border-box-7>
                </div>
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">浴巾</div>
                    </div>
                  </dv-border-box-7>
                </div>
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">面巾</div>
                    </div>
                  </dv-border-box-7>
                </div>
                <div
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item']"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">0</div>
                      <div class="box_name">地巾</div>
                    </div>
                  </dv-border-box-7>
                </div>
              </div>
              <div class="app-card-content" v-if="assetsdetailList.length > 0">
                <div
                  v-for="(item, index) in assetsdetailList"
                  :key="index"
                  :style="'width: ' + (100 / lineNumber - 2.2) + '%'"
                  :class="['app-card-content_item', getBorderClass(index)]"
                >
                  <dv-border-box-7 :color="['#3D5AA1']">
                    <div class="fenlei">
                      <div class="box">
                        <countTo
                          :startVal="item?.uncount || 0"
                          :endVal="item?.count || 0"
                          :duration="3000"
                        ></countTo>
                      </div>
                      <div class="box_name">{{ item.name }}</div>
                    </div>
                  </dv-border-box-7>
                </div>
              </div>
              <div
                v-if="msg.count > 0"
                style="color: #40C5FF; font-size: 32px"
              >
                当前读数：{{ msg.count }}
              </div>
              <div
                v-if="msg.unknown_bucao_count > 0"
                style="color: red; font-size: 32px"
              >
                当前未知芯片数量：{{ msg.unknown_bucao_count }}
              </div>
            </div>
            <div class="anniu">
              <dv-border-box-12
                class="annius"
                v-if="xin.status == 0"
                @click="zeros"
                >归零</dv-border-box-12
              >
              <dv-border-box-12
                class="annius"
                v-if="xin.status == 0"
                @click="close"
                >{{ show_close ? "继续" : "暂停" }}</dv-border-box-12
              >
              <dv-border-box-12
                class="annius"
                @click="wash"
                v-if="xin.status == 0"
                >送洗</dv-border-box-12
              >
            </div>
          </dv-border-box-13>
        </van-col>
      </van-row>
    </dv-border-box-8>
  </dv-full-screen-container>
</template>
<script>
import {
  Col,
  Row,
  Tab,
  Tabs,
  Icon,
  List,
  Cell,
  CellGroup,
  PullRefresh,
  Button,
  Card,
  Popup,
  Tag,
  Swipe,
  SwipeItem,
  Image as VanImage,
  Notify,
  Overlay,
  Loading,
  CountDown,
  Circle,
  Progress,
  Checkbox,
  CheckboxGroup,
  Field,
  Dialog,
  Collapse,
  CollapseItem,
  Radio,
} from "vant";
import { reactive, ref, toRefs } from "vue";
import mqtt from "mqtt";
import countTo from "./vue-countTo.vue";
import store from "../store/index";
import api from "../utils/api";
import _ from "lodash";
export default {
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Icon.name]: Icon,
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [PullRefresh.name]: PullRefresh,
    [Button.name]: Button,
    [Card.name]: Card,
    [Popup.name]: Popup,
    [Tag.name]: Tag,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [VanImage.name]: VanImage,
    [Notify.name]: Notify,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
    [CountDown.name]: CountDown,
    [Circle.name]: Circle,
    [Progress.name]: Progress,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Field.name]: Field,
    [Dialog.name]: Dialog,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Radio.name]: Radio,
    countTo,
  },

  setup(props, context) {
    console.log("props", toRefs(props), context);
    const num = store.state.edition;
    const state = reactive({
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
    });
    const onLoad = () => {
      if (state.refreshing) {
        state.list = [];
        state.refreshing = false;
      }
      state.finished = true;
    };
    const onRefresh = () => {
      // 清空列表数据
      state.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      state.loading = true;
    };
    const show = ref(false);

    const show_close = ref(false);
    const close = () => {
      show_close.value = !show_close.value;
    };
    const activeNames = ref("1");
    const duka = ref({
      text: "已断开连接",
      color: "color:red",
    });
    const active = ref("1");
    return {
      active,
      duka,
      num,
      intervalId: ref(null),
      datatime: 60000,
      listheight: 3.55 + "rem",
      lineNumber: 3,
      client: {},
      mtopic: ref(""),
      mtopicheart: ref(""),
      reader_device_code: ref(""),
      msg: ref(""),
      xin: ref({}),
      uncount: ref(0),
      assetsdetailList: ref([]),
      service_lsit: ref([]),
      state: ref(state),
      device: ref({}),
      time: ref(""),
      factory: ref({}),
      onRefresh,
      show,
      show_close,
      close,
      onLoad,
      loading: ref(false),
      device_code: ref(""),
      clientId: ref(""),
      staff_code: ref(""),
      // dpod: ref(""),
      status: ref(false),
      status_msg: ref(""),
      status_msgs: ref([
        {
          msg: "设备获取",
          status: false,
          warn: false,
        },
        {
          msg: "芯任桌面智慧工作站设备配置",
          status: false,
          warn: false,
        },
        {
          msg: "芯任桌面读卡器设备配置",
          status: false,
          warn: false,
        },
        {
          msg: "芯任桌面票据打印机设备配置",
          status: false,
          warn: false,
        },
        {
          msg: "芯任桌面扫描枪设备配置",
          status: false,
          warn: false,
        },
        {
          msg: "关联工厂获取",
          status: false,
          warn: false,
        },
        {
          msg: "云端连接",
          status: false,
          warn: false,
        },
        {
          msg: "云端订阅",
          status: false,
          warn: false,
        },
      ]),
      status_percentage: ref(false),
      networks: ref(false),
      network_time: 5000,
      network_Id: null,
      staff_info: ref({}),
      activeNames,
      click: ref(false),
      recvstaffinfo: [],
      showorder: ref(false),
      recvstaff_sense: ref(),
      recvstaff_count: ref(),
      recvstaff_allinfo: ref(),
      flag: ref(),
      restartsnum: ref(0),
      restartsts: ref(),
    };
  },
  mounted() {
    // const url = location.hash;
    // console.log("url", url);
    // if (url && url.indexOf("?") != -1) {
    //    console.log(url.split('&')[0].split('=')[1]);
    // }
    document.title = `芯任收污送洗`;
    this.network();
    this.network_refreh();
    if (!this.$route.query.staff_code) {
      Notify({ message: "请输入员工编号", duration: 30000 });
      return;
    }
    if (!this.$route.query.device_code) {
      Notify({ message: "请输入设备编码", duration: 30000 });
      return;
    }
    if (this.$route.query.device_code && this.$route.query.staff_code) {
      this.device_code = this.$route.query.device_code;
      this.staff_code = this.$route.query.staff_code;
      this.clientId =
        this.device_code + "_" + Math.floor(Math.random() * 10 + 1);
      console.log(this.clientId);
    }
    this.staffgetstaffinfo();
    this.time = this.date(new Date().getTime());
    this.devicefetch();
    // this._isMobile("connect_cloud")
  },
  watch: {
    msg: function (newVal, endVal) {
      console.log(newVal, endVal);
      if (endVal.count && endVal != newVal) {
        if (endVal.code == newVal.code) {
          this.uncount = endVal.count;
          if (newVal.records) {
            newVal = newVal.records.map((i, e) => {
              i.uncount = endVal.records[e].count;
            });
          }
        } else {
          this.uncount == 0;
          newVal = newVal.records.map((i) => {
            i.uncount = 0;
          });
        }
      }
    },
    show_close: function (newVal) {
      if (newVal == true) {
        console.log("暂停");
        this.deskservice("2");
      } else {
        console.log("继续");
        this.deskservice("3");
      }
    },
  },
  unmounted() {
    // 在页面销毁后，清除计时器
    this.clear();
  },
  methods: {
    resetdevice() {
      if (this.resetnumts) {
        if (
          new Date().getTime() - this.resetnumts < 3000 &&
          this.resetnum >= 5
        ) {
          Notify({
            message: "请勿频繁操作",
            duration: 3000,
            type: "danger",
          });
          this._isMobile("often_verify");
          this.resetnum = 0;
        } else {
          this.resetnum++;
        }
        if (new Date().getTime() - this.resetnumts > 3000) {
          this.resetnum = 0;
        }
      }
      this.resetnumts = new Date().getTime();
      this.loading = true;
      api
        .resetdevice({
          device_code: this.$route.query.device_code,
          cmd: "1",
        })
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            this.loading = res.loading;
            Notify({
              message: "已发送重启命令",
              duration: 1000,
              type: "success",
            });
          }
          //  else {
          //   Notify({ message: "重置读卡失败", duration: 1000, type: "danger" });
          // }
        });
      // .catch((err) => {
      //   console.log(err);
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    _isMobile(e) {
      this.flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      if (this.flag == null) {
        console.log("==>PC端");
      } else {
        console.log("==>移动端");
        window.Android.playTone(e);
      }
    },
    wash() {
      api
        .staffrecvstaffinfo({
          bandb_code: this.device.bandb.code,
        })
        .then((res) => {
          console.log(res);
          if (res.data.errcode == 0) {
            this.recvstaffinfo = res.data.data.recv_staff;
            // this.showorder = true;
            this.show = true;
          }
        });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
      // this.show = true;
    },
    restart() {
      this.login = false;
    },
    restarts() {
      if (this.restartsts) {
        if (
          new Date().getTime() - this.restartsts < 3000 &&
          this.restartsnum >= 5
        ) {
          Notify({
            message: "请勿频繁操作",
            duration: 3000,
            type: "danger",
          });
          this._isMobile("often_verify");
          this.restartsnum = 0;
        } else {
          this.restartsnum++;
        }
        if (new Date().getTime() - this.restartsts > 3000) {
          this.restartsnum = 0;
        }
      }
      this.restartsts = new Date().getTime();
      this.login = false;
    },
    network() {
      if (window.navigator.onLine == true) {
        let img = new Image();
        img.src =
          "http://fdfs.singran.vip/group1/M00/39/36/rBEAA2DAhiaAcZK4AADaqwfWS6s275.png";
        img.onload = () => {
          console.log("网络已连接");
          // this.status_msg = "网络已连接";
        };
        img.onerror = () => {
          console.log("无网络连接，请检查网络");
          this.status_msg = "无网络连接，请检查网络";
          if (this.status_msgs) {
            this.status_msgs.map((i) => {
              i.status = false;
              i.warn = false;
            });
          }
          Dialog.alert({
            title: "网络已断开连接",
            message: "请检查网络",
          }).then(() => {});
          this._isMobile("cut_off_internet");
        };
      } else {
        console.log("网络未连接--请检查网络");
      }
    },
    senses(e, i) {
      console.log("点击选择的配送员", e, i);
      this.recvstaff_sense = e.code;
      this.recvstaff_count = i;
      this.recvstaff_allinfo = e;
    },
    mqtt() {
      this.client = mqtt.connect("ws://mqtt.singran.vip:8083/mqtt", {
        clientId: this.clientId,
        clean: true,
        // reconnect: true,
        keepAliveInterval: 30,
        // reconnectInterval: 10,
        username: "xinren_factory",
        password: "xinren20200807",
      });
      // 断开发起重连
      // this.client.on("reconnect", (error) => {
      //   console.log("正在重连:", error);
      //   this.status_msg = "云端尝试重新连接中";
      //   this.status = false;
      // });
      // 链接异常处理
      this.client.on("error", (error) => {
        console.log("连接失败:", error);
        this.status_msg = "云端断开连接";
        this.status = false;
        Dialog.alert({
          title: "云端断开连接",
          message: "平板未连接上云端，请联系芯任人员",
        }).then(() => {
          // on close
        });
        this._isMobile("connect_cloud");
      });
      this.client.on("connect", (e) => {
        console.log("连接成功", e);
        setTimeout(() => {
          this.status_msg = "云端连接成功";
          this.status_msgs[6].status = true;
          this.status = true;
        }, 1000);
        this.client.subscribe(this.mtopic, (err) => {
          if (!err) {
            console.log("订阅成功:" + this.mtopic);
            setTimeout(() => {
              this.status_msg = "云端订阅成功";
              this.status_msgs[7].status = true;
              this.status = true;
            }, 1200);
            setTimeout(() => {
              this.status_msg = "等待读卡中...";
            }, 1300);
          } else {
            setTimeout(() => {
              this.status_msg = "云端订阅失败";
              this.status = false;
              Dialog.alert({
                title: "云端订阅失败",
                message: "请刷新",
              }).then(() => {
                // on close
              });
              this._isMobile("connect_cloud");
            }, 1200);
          }
        });
        this.client.subscribe(this.mtopicheart, (err) => {
          if (!err) {
            console.log("订阅成功:" + this.mtopicheart);
          }
        });
        this.client.on("message", (topic, message) => {
          if (JSON.parse(String(message)).heart_ts) {
            this.hearts = JSON.parse(String(message));
            this.heart_ts = this.heart?.heart_ts || 0;
          }
          this.msg = JSON.parse(String(message));
          if (topic.indexOf("service") != -1) {
            console.log("msg", this.msg);
            if (this.msg.status == 0) {
              console.log("进行中");
              this.status_percentage = true;
              this.xin.count = this.msg.count;
              this.xin.code = this.msg.service;
              this.xin.status = this.msg.status;
              this.xin.date = this.date(this.msg.ts);
              this.assetsdetailList = this.msg.records;
              this.throttlefactorydesk();
            } else {
              console.log("已关闭");
              this.show_close = false;
              if (!this.msg.manual) {
                console.log("自动关闭");
                this.dataRefreh();
              } else {
                console.log("手动关闭");
                this.msg = {};
              }
              setTimeout(() => {
                this.restart();
              }, 1000);
            }
          }

          if (topic.indexOf("heart") != -1) {
            console.log("心跳", this.msg);
            if (this.msg.connect == 0) {
              this.duka = {
                text: "已断开连接",
                color: "color:red",
              };
              Dialog.alert({
                title: "读卡设备已断开网络，请检查网络",
                message: "请检查网络",
              }).then(() => {});
              this._isMobile("rfid_device_off");
            } else {
              this.duka = {
                text: "网络已连接",
                color: "color:green",
              };
            }
          }
        });
      });
    },
    call() {
      this.showorder = false;
      this.recvstaff_sense = "";
      this.recvstaff_count = "";
      this.recvstaff_allinfo = "";
    },
    jump() {
      this.showorder = false;
      this.recvstaff_sense = "";
      this.recvstaff_count = "";
      this.recvstaff_allinfo = "";
      this.show = true;
    },
    submits() {
      this.showorder = false;
      console.log(this.recvstaff_sense, this.recvstaff_count);
      this.show = true;
    },
    readerstatus() {
      api
        .readerstatus({
          device_code: this.device?.dpod?.lctf || this.device?.device?.lctf,
        })
        .then((res) => {
          console.log("首次心跳", res);
          if (res.data.data.status == 1) {
            this.duka = {
              text: "网络已连接",
              color: "color:green",
            };
          } else {
            this.duka = {
              text: "已断开连接",
              color: "color:red",
            };
            Dialog.alert({
              title: "读卡设备已断开网络，请检查网络",
              message: "请检查网络",
            }).then(() => {});
            this._isMobile("rfid_device_off");
          }
        });
    },
    staffgetstaffinfo() {
      api
        .staffgetstaffinfo({
          staff_code: this.$route.query.staff_code,
          staff_type: "bandb",
        })
        .then((res) => {
          console.log("staff", res.data);
          if (res.data.errcode == 0) {
            res.data.data.staff_info.avatar =
              "http://api.singran.vip/staffavatar/" +
              res.data.data.staff_info.code;
            this.staff_info = res.data.data.staff_info;
            if (res.data.data.staff_info.role_code == "bandb_manager") {
              res.data.data.staff_info.role = "总经理";
            } else if (res.data.data.staff_info.role_code == "bandb_poster") {
              res.data.data.staff_info.role = "驿站管理员";
            } else if (res.data.data.staff_info.role_code == "bandb_cleaner") {
              res.data.data.staff_info.role = "保洁员";
            }
          }
        });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    factorydesk(e) {
      api.factorydesk({ service: this.xin.code || e }).then((res) => {
        console.log("批次", res);
        this.state.list = res.data.data.rooms;
        res.data.data.bucao_summary.map((e) => {
          e.avatar = e.name.split("")[0];
        });
        this.state.bucao_summary = res.data.data.bucao_summary;
        if (this.state.refreshing) {
          this.state.list = [];
          this.state.bucao_summary = [];
          this.state.refreshing = false;
        }
        if (res.data.data.bucao_summary && e) {
          this.assetsdetailList = res.data.data.bucao_summary;
          this.xin.count = res.data.data.bucao_count;
        }
        this.state.finished = true;
      });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    throttlefactorydesk: _.throttle(function () {
      this.factorydesk();
    }, 500),
    devicefetch() {
      api.devicefetch({ device_code: this.device_code }).then((res) => {
        console.log("设备", res);
        if (res.data.errcode == 0) {
          this.status_msg = "设备获取成功";
          this.status_msgs[0].status = true;
          this.status = true;

          if (res.data.data.device.category == "dpod") {
            setTimeout(() => {
              if (res.data.data.device.code) {
                this.status_msg = res.data.data.device.name + "设备配置成功";
                this.status_msgs[1].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[1].msg + "失败";
                this.status = false;
                this._isMobile("check_belong");
                return;
              }
            }, 200);
            setTimeout(() => {
              if (res.data.data.device.lctf) {
                this.status_msg =
                  res.data.data.device.lctf_name + "设备配置成功";
                this.status_msgs[2].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[2].msg + "失败";
                this.status = false;
                this._isMobile("check_belong");
                return;
              }
            }, 400);
            setTimeout(() => {
              if (res.data.data.device.prnt) {
                this.status_msg =
                  res.data.data.device.prnt_name + "设备配置成功";
                this.status_msgs[3].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[3].msg + "失败";
                this.status_msgs[3].warn = true;
                console.log(this.status_msgs[3]);
                this.status = false;
                return;
              }
            }, 600);
            setTimeout(() => {
              if (res.data.data.device.bcsa) {
                this.status_msgs[4].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[4].msg + "失败";
                this.status_msgs[4].warn = true;
                console.log(this.status_msgs[4]);
                this.status = false;
                return;
              }
            }, 800);
            this.mtopic = "device/" + res.data.data.device.lctf + "/service";
            this.mtopicheart = "device/" + res.data.data.device.lctf + "/heart";
          } else if (res.data.data.device.category == "lctf") {
            setTimeout(() => {
              if (res.data.data.dpod.code) {
                this.status_msg = res.data.data.dpod.name + "设备配置成功";
                this.status_msgs[1].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[1].msg + "失败";
                this.status = false;
                this._isMobile("check_belong");
                return;
              }
            }, 200);
            setTimeout(() => {
              if (res.data.data.dpod.lctf) {
                this.status_msg = res.data.data.dpod.lctf_name + "设备配置成功";
                this.status_msgs[2].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[2].msg + "失败";
                this.status = false;
                this._isMobile("check_belong");
                return;
              }
            }, 400);
            setTimeout(() => {
              if (res.data.data.dpod.prnt) {
                this.status_msg = res.data.data.dpod.prnt_name + "设备配置成功";
                this.status_msgs[3].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[3].msg + "失败";
                this.status_msgs[3].wran = true;
                this.status = false;
                return;
              }
            }, 600);
            setTimeout(() => {
              if (res.data.data.dpod.bcsa) {
                this.status_msg = res.data.data.dpod.bcsa_name + "设备配置成功";
                this.status_msgs[4].status = true;
                this.status = true;
              } else {
                this.status_msg = this.status_msgs[4].msg + "失败";
                this.status_msgs[4].wran = true;
                this.status = false;
                return;
              }
            }, 800);

            this.mtopic = "device/" + res.data.data.dpod.lctf + "/service";
            this.mtopicheart = "device/" + res.data.data.dpod.lctf + "/heart";
            console.log("mtopicheart", this.mtopicheart);
          }

          this.device = res.data.data;
          this.readerstatus();
          // this.dpod = res.data.data.dpod;
          this.bandbfactory(res.data.data?.device);
        } else {
          this.status_msg = "设备获取失败";
          this.status = false;
          return;
        }
      });
      // .catch((err) => {
      //   console.log("中台服务器故障,请联系管理员", err);
      //   Dialog.alert({
      //     title: "中台服务器故障,请联系管理员！",
      //     message: "请检查平板归属与设备类型",
      //   }).then(() => {
      //     // on confirm
      //   });
      // });
    },
    deskfetchreadingservice() {
      // console.log(this.device.dpod.lctf, this.device.device.lctf);
      api
        .deskfetchreadingservice({
          device_code: this.device?.dpod?.lctf || this.device?.device?.lctf,
        })
        .then((res) => {
          console.log("首次", res.data);
          if (res.data.errcode == 0 && res.data.data.service) {
            this.factorydesk(res.data.data.service);
            this.status_percentage = true;
            this.xin.status = 0;
            this.xin.code = res.data.data.service;
          }
        });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    bandbfactory(e) {
      api.bandbfactory({ bandb_code: e.bandb_code }).then((res) => {
        console.log("关联工厂", res);
        if (res.data.errcode == 0) {
          this.factory = res.data.data?.factory;
          this.status_msgs[5].status = true;
          this.mqtt();
          this.deskfetchreadingservice();
        } else {
          this.status_msgs[5].status = false;
          return;
        }
      });
      // .catch((err) => {
      //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
      // });
    },
    deskservice(e) {
      api
        .servicecontrol({ code: this.xin.code, status: e, device_type: "desk" })
        .then((res) => {
          console.log("操作", e, this.xin.code, res);
          if (res.data.errcode == 0 && e == 1) {
            Notify({ message: "已关闭", duration: 1000, type: "danger" });
          } else if (res.data.errcode == 0 && e == 2) {
            Notify({ message: "已暂停", duration: 1000, type: "warning" });
          } else if (res.data.errcode == 0 && e == 3) {
            Notify({ message: "已继续", duration: 1000, type: "success" });
          }
        });
    },
    submit() {
      if (!this.click) {
        this.click = true;
        console.log("下单");
        Dialog.confirm({
          title: "请选择",
          message: "请选择布草送洗类别 收污、返洗",
          confirmButtonText: "收污",
          cancelButtonText: "返洗",
        })
          .then(() => {
            // on confirm
            console.log("收污");
            let data;
            if (this.device.device.category == "dpod") {
              data = {
                service: this.xin.code,
                hotel_code: this.device.hotel.code,
                factory_code: this.factory.code,
                device_code: this.device.device.code,
                staff_code: this.staff_code,
                app_code: this.$route.query.app_id,
                app_scene: this.$route.query.app_scene,
                recv_staff: this.recvstaff_sense,
                wash_type: "wash",
              };
            } else if (this.device.device.category == "lctf") {
              data = {
                service: this.xin.code,
                hotel_code: this.device.hotel.code,
                factory_code: this.factory.code,
                device_code: this.device.dpod.code,
                staff_code: this.staff_code,
                app_code: this.$route.query.app_id,
                app_scene: this.$route.query.app_scene,
                recv_staff: this.recvstaff_sense,
                wash_type: "wash",
              };
            }
            api.deskcommitorder(data).then((res) => {
              if (res.data.errcode == 0) {
                this.show = !this.show;
                Notify({
                  message: "已提交订单",
                  duration: 1000,
                  type: "success",
                });
                this.status_percentage = !this.status_percentage;
                this.zero("order");
                setTimeout(() => {
                  console.log("重复点击");
                  this.click = false;
                }, 1000);
              } else {
                Notify({ message: res.data.errmsg, duration: 1000 });
              }
            });
            // .catch((err) => {
            //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
            // });
          })
          .catch(() => {
            // on cancel
            console.log("返洗");
            let data;
            if (this.device.device.category == "dpod") {
              data = {
                service: this.xin.code,
                hotel_code: this.device.hotel.code,
                factory_code: this.factory.code,
                device_code: this.device.device.code,
                staff_code: this.staff_code,
                app_code: this.$route.query.app_id,
                app_scene: this.$route.query.app_scene,
                recv_staff: this.recvstaff_sense,
                wash_type: "back",
              };
            } else if (this.device.device.category == "lctf") {
              data = {
                service: this.xin.code,
                hotel_code: this.device.hotel.code,
                factory_code: this.factory.code,
                device_code: this.device.dpod.code,
                staff_code: this.staff_code,
                app_code: this.$route.query.app_id,
                app_scene: this.$route.query.app_scene,
                recv_staff: this.recvstaff_sense,
                wash_type: "back",
              };
            }
            api.deskcommitorder(data).then((res) => {
              if (res.data.errcode == 0) {
                this.show = !this.show;
                Notify({
                  message: "已提交订单",
                  duration: 1000,
                  type: "success",
                });
                this.zero("order");
                setTimeout(() => {
                  console.log("重复点击");
                  this.click = false;
                }, 5000);
              } else {
                Notify({ message: res.data.errmsg, duration: 1000 });
              }
            });
            // .catch((err) => {
            //   Notify({ message: err.errmsg, duration: 1000, type: "danger" });
            // });
          });
      }
    },
    refresh() {
      this.$router.go(0);
    },
    zeros() {
      this.zero("zero");
    },
    zero(e) {
      console.log("归零");
      this.state.list = [];
      this.state.bucao_summary = [];
      this.assetsdetailList = [];
      this.recvstaff_sense = "";
      this.recvstaff_count = "";
      this.recvstaff_allinfo = "";
      this.show = false;
      this.xin.status = 1;
      this.msg = {};
      this.uncount = 0;
      this.clear();
      if (e == "zero") {
        this.deskservice("1");
      }
    },
    network_refreh() {
      if (this.network_Id) {
        clearInterval(this.network_Id);
      } else {
        console.log(`打开定时器${this.network_time}`);
        this.network_Id = setInterval(() => {
          this.network();
        }, this.network_time);
      }
    },
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId) {
        clearInterval(this.intervalId);
      } else {
        console.log(`打开定时器${this.datatime}`);
        this.intervalId = setInterval(() => {
          this.zero();
        }, this.datatime);
      }
    },
    // 停止定时器
    clear() {
      console.log(`关闭定时器`);
      clearInterval(this.intervalId); //清除计时器
      this.intervalId = null; //设置为null
    },
    date(e) {
      var date = new Date(e);
      var YY = date.getFullYear() + "-";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hh =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var mm =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var ss =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return YY + MM + DD + " " + hh + mm + ss;
    },
    getBorderClass(index) {
      index += 1;
      let className = "";
      let lineNumber = this.lineNumber; // 每行行显示个数
      let total = this.assetsdetailList.length; // 总个数
      let line = Math.ceil(total / lineNumber); // 一共有几行
      let currentLine = 0; // 当前在第几行,这里从1开始
      let currentIndex = 0; // 当前在某行的第几个
      currentLine = Math.ceil(index / lineNumber);
      currentIndex = index % lineNumber === 0 ? lineNumber : index % lineNumber;
      // 右下边框
      if (currentLine < line && currentIndex < lineNumber) {
        className = "border-bottom border-right";
      }
      // 右边框
      if (currentLine === line && currentIndex < lineNumber) {
        className = "border-right";
      }
      // 下边框
      if (currentLine < line && currentIndex === lineNumber) {
        className = "border-bottom";
      }
      // 无边框
      if (currentLine === line && currentIndex === lineNumber) {
        className = "";
      }
      return className;
    },
  },
};
</script>
<style lang='less' scoped>
/deep/.van-collapse-item__content {
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0);
}
/deep/.van-cell {
  background: rgba(0, 0, 0, 0);
  padding: 3px;
  justify-content: space-between;
  align-items: center;
}
/deep/.van-cell__title,
.van-cell__value {
  flex: none;
  color: #fff;
}
/deep/.van-popup {
  background: rgba(0, 0, 0, 0);
}
/deep/.van-cell-group {
  background: rgba(0, 0, 0, 0);
}
/deep/.van-tabs__nav {
  background-color: rgba(0, 0, 0, 0);
}
/deep/.van-tabs--line .van-tabs__wrap {
  height: 0.38889rem;
}
/deep/.van-tab {
  color: #40c5ff;
}
/deep/.van-tabs__line {
  background-color: #40c5ff;
}
.bj {
  background-image: url("../assets/bj.jpg");
}
.logo {
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 2px;
  margin-right: 3px;
}
.logos {
  width: 20px;
  height: 10px;
  background: #fff;
  border-radius: 5px;
}
.info {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 12px;
  height: 10px;
  margin-bottom: 2px;
}
.infotime {
  font-size: 12px;
  color: #fff;
  text-align: left;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.onheard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  font-size: 16px;
  color: #fff;
}
.heard {
  height: 16px;
  width: 16px;
}
.device {
  color: #fff;
  font-size: 10px;
  width: 25%;
  text-align: right;
  margin-right: 10px;
}
.border {
  border-radius: 5px;
  padding: 3px;
}
.app-card-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: rgba(12, 24, 51, 0);
}
.app-card-content_item {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  margin: 4px;
}
.fenlei {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.box {
  font-size: 30px;
  color: #40c5ff;
}
.box_name {
  font-size: 18px;
  color: #4a9cbd;
}
.btn {
  padding-top: 5px;
}
.overflow {
  overflow-y: scroll;
}
.overflow::-webkit-scrollbar {
  display: none;
}
.anniu {
  color: #7ce7fd;
  font-size: 12px;
  display: flex;
  justify-content: center;
}
.annius {
  width: 100px;
  height: 40px;
  margin-left: 5px;
  line-height: 40px;
  text-align: center;
  color: #4cc9d9;
  font-size: 25px;
}
.dialog {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 12px;
  color: #40c5ff;
  margin: 6px 0;
}
.dialog_right {
  padding-left: 12px;
}
.dialog_left {
  font-size: 10px;
  padding-right: 12px;
}
.dialog_button {
  display: flex;
  justify-content: space-around;
}
.dialog_button_but {
  height: 38px;
  margin-top: 5px;
  width: 45%;
  font-size: 16px;
  line-height: 38px;
  color: #40c5ff;
}
.popup {
  width: 300px;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0);
}
.preview {
  font-size: 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}
.status_msg {
  border-top: 1px solid rgb(255, 255, 255);
  padding: 2px;
  text-align: left;
}
.status {
  width: 20%;
  margin: 0 auto;
  height: 150px;
  overflow-y: scroll;
}
.status::-webkit-scrollbar {
  display: none;
}
.bucaos {
  display: flex;
  color: #fff;
}
.button {
  width: 80px;
  height: 40px;
  position: fixed;
  right: 10px;
  bottom: 10px;
}
.duka {
  font-size: 8px;
}
.avatar {
  width: 80px;
  height: 50px;
  margin-left: 13px;
  line-height: 50px;
  color: #40c5ff;
  font-size: 20px;
}
.boxdiv {
  position: absolute;
  background: rgba(22, 39, 78);
  bottom: 20%;
  left: 50%;
  transform: translate(-50%);
  z-index: 9;
}
.height {
  height: 200px;
  overflow-y: scroll;
}
.height::-webkit-scrollbar {
  display: none;
}
.buttonS {
  width: 100px;
  height: 40px;
  position: fixed;
  right: 100px;
  bottom: 10px;
}
</style>